import React from "react";

const experienceContent = [
  {
    year: "2024 - Present",
    position: "Software Engineer",
    companyName: "Fuse Finance (NY, USA)",
    details:
      "Development of an AI-powered Loan Origination System focused on workflow automation and low-code customization. Implemented AI agents to significantly enhance lending processes and user experience.",
  },
  {
    year: "2015 - Present",
    position: "Founder",
    companyName: "Evolve Software & Design (AR)",
    details:
      "Leading Java and AWS-based high-concurrency applications (L2J). Growth Hacking, custom software development, and strategic consulting. https://www.evolve-sdf.com",
  },
  {
    year: "2016 - 2018",
    position: "Senior Project Leader",
    companyName: "Emprendimientos Satelitales (AR)",
    details:
      "Project leadership and core development of the Sistema de Turnos Online for Buenos Aires City’s Vehicle Technical Inspection (SUVTV CABA). Successfully coordinated technical teams and stakeholders.",
  },
  {
    year: "2015 - 2016",
    position: "Software Development Engineer",
    companyName: "Minder S.A. (AR)",
    details:
      "Development and analysis for RecibosElectronicos.com, involving Oracle E-Business Suite ERP. Specialized in PL/SQL, Oracle Forms, Oracle Reports, and senior-level system analysis.",
  },
  {
    year: "2013 - 2015",
    position: "Software Developer",
    companyName: "Federación Argentina de Deportes para Ciegos (FADEC) (AR)",
    details:
      "Design, development, and maintenance of the official website for the Argentine Blind Sports Federation, enhancing accessibility and user engagement.",
  },
  {
    year: "2011 - 2013",
    position: "Software Developer",
    companyName: "Empiria IT (AR)",
    details:
      "Junior-level development of Android applications and C#.NET projects, collaborating closely with senior team members and contributing to multiple client-focused solutions.",
  },
];


const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.companyName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
