import React from "react";

const skillsContent = [
  "JAVA",
  "PHP",
  "NODE JS",
  "AWS",
  "LINUX",
  "SQL & NO-SQL",
  "ANGULAR/REACT/VUE",
  "LEADERSHIP",
  "AI INTEGRATION",
  "LOW-CODE AUTOMATION",
  "SYSTEM ARCHITECTURE",
  "PROJECT MANAGEMENT",
];

const Skills = () => {
  return (
    <div className="row">
      {skillsContent.map((skill, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <h6 className="text-uppercase open-sans-font text-center py-2 px-3 bg-warning rounded">
            {skill}
          </h6>
        </div>
      ))}
    </div>
  );
};

export default Skills;