import React from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n'; // Importa la configuración de i18n
import { Dropdown } from 'react-bootstrap';

function LanguageSelector() {
    const { t, i18n } = useTranslation();

    const handleLanguageChange = (lng) => {
        i18n.changeLanguage(lng);
        //className="d-flex justify-content-end align-items-center">
    };

    return (
        <Dropdown>
            <ul className="list-unstyled mb-0"> {/* Agrega clases y estilos según tus necesidades */}
                <Dropdown.Toggle className="icon-box" as="li" variant="secondary" id="dropdown-basic">
                    <i className="fa fa-globe"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleLanguageChange('es')}>
                        <img src="img/spain.png" width="20%"/> Español
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLanguageChange('en')}>
                        <img src="img/uk.png" width="20%"/> Inglés
                    </Dropdown.Item>
                    {/* Agrega más opciones de idioma según sea necesario */}
                </Dropdown.Menu>
            </ul>
        </Dropdown>
    );
}

export default LanguageSelector;
