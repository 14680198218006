import React from "react";
import { useTranslation } from 'react-i18next';
import '../i18n'; // Importa la configuración de i18n

const Address = () => {
    const { t } = useTranslation();
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">{t('address_point')}</span>Buenos Aires ,
        Argentina.
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">{t('mail_me')}</span>{" "}
        <a href="mailto:hello@ezequielbarnes.com">hello@ezequielbarnes.com</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">{t('whatsapp_me')}</span>{" "}
        <a href="https://wa.me/5491123281783">+549 11 2328-1783</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
