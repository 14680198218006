import React from "react";
import { useTranslation } from 'react-i18next';
import '../../i18n'; // Importa la configuración de i18n

const calculateAge = (birthdate) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const PersonalInfo = () => {
  const { t } = useTranslation();
  const personalInfoContent = [
    { meta: t('first_name'), metaInfo: "Ezequiel" },
    { meta: t('last_name'), metaInfo: "Barnes" },
    { meta: t('age'), metaInfo: calculateAge("1992-10-10") },
    { meta: t('nationality'), metaInfo: t('argentinian') },
    { meta: t('work_style'), metaInfo: t('remote') },
    { meta: t('address'), metaInfo: "Buenos Aires, Argentina" },
    { meta: t('phone'), metaInfo: "+5491123281783" },
    { meta: t('email'), metaInfo: "hello@ezequielbarnes.com" },
    { meta: "Skype", metaInfo: "ezequielbarnes" },
    { meta: t('languages'), metaInfo: "Español, English, Portuguese" },
  ];

  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;